<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        height="40"
      >
        <v-toolbar-title>Dimension</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          small
          to="/cost-accounting/dimensions"
        >
          <v-icon
            left
            dark
          >mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <editor
          :initial="dataItem"
          @data="save"
        ></editor>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      dataItem: {},
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getUserDefaults",
      immediate: true,
    },
  },
  methods: {
    save(data) {
      const url = `/dimensions/${data.id}`;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then(() => {
          self.$router.push("/cost-accounting/dimensions");
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getUserDefaults(val) {
      const self = this;
      this.$store
        .dispatch("get", `/dimensions/${val}`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.dataItem = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
</style>