<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          filled
          readonly
          label="Dimension Name"
          v-model="record.DimName"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Dimension Description"
          v-model="record.DimDesc"
        ></v-text-field>
      </v-col>

      <v-checkbox
        v-model="record.DimStatus"
        label="Active"
      ></v-checkbox>

    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-btn
          color="accent"
          @click="sendData"
          :loading="loader"
        >
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    record: {},
    drivers: [],
    loader: false,
    priceLists: [],
    warehouses: [],
    salesEmployees: [],
    itemGroups: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.loader = true;
      this.$emit("data", this.record);
    },
    getWarehouses() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/warehouse`)
        .then((res) => {
          self.warehouses = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.salesEmployees = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDrivers() {
      const self = this;
      this.$store
        .dispatch("get", `/drivers`)
        .then((res) => {
          self.drivers = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getItemGroups() {
      const self = this;
      this.$store
        .dispatch("get", `/itemgroup`)
        .then((res) => {
          self.itemGroups = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    //this.getWarehouses();
    // this.getDrivers();
    //  this.getEmployees();
    //this.getItemGroups();
  },
};
</script>